
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$sites-bovo-online-werkomgeving-primary: mat.define-palette(mat.$indigo-palette);
$sites-bovo-online-werkomgeving-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$sites-bovo-online-werkomgeving-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$sites-bovo-online-werkomgeving-theme: mat.define-light-theme((
  color: (
    primary: $sites-bovo-online-werkomgeving-primary,
    accent: $sites-bovo-online-werkomgeving-accent,
    warn: $sites-bovo-online-werkomgeving-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($sites-bovo-online-werkomgeving-theme);

/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: RobotoSlab;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: url('assets/fonts/RobotoSlab-Regular.woff2') format('woff2');
}


@font-face {
  font-family: RobotoSlab;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  src: url('assets/fonts/RobotoSlab-Bold.woff2') format('woff2');
}

body {
  margin: 0;
  background-color: #3FBBCE0D;
  height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1 {
  font-size: 58px;
  font-weight: 700;
  margin-bottom: 40px;
}

h2 {
  font-family: RobotoSlab, serif;
  font-size: 25px;
  font-weight: 400;
}

h3 {
  font-family: RobotoSlab, serif;
  font-size: 16px;
  font-weight: 700;
}

p {
  font-family: RobotoSlab, serif;
  font-size: 14px;
  margin: 0;
}

p:empty {
  height: 15px;
}

li {
  font-family: RobotoSlab, serif;
  font-size: 14px;
  font-weight: 400;
}

a {
  font-family: RobotoSlab, serif;
  font-size: 12px;
  font-weight: 400;
}

.text-wrapper::ng-deep p {
  font-family: RobotoSlab, serif;
  font-size: 14px;
  font-weight: 400;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.highlight {
  background-color: #D8F1F5;
}

@keyframes flyInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes flyOutToRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.fly-in-dialog {
  animation: flyInFromRight 0.3s ease-out;
}

.fly-out-dialog {
  animation: flyOutToRight 0.3s ease-out;
}

.disable-backdrop-shadow {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
}

.mat-dialog-container {
  box-shadow: none;
}

.cdk-overlay-backdrop {
  background-color: rgba(0, 0, 0, 0.1);
}
